/**
 *
  {
    id: ,
    title:"",
    platform: "",
    link: ""
  },
 */

const certificationsData = [
  {
    id: 1,
    title:"Foundations of Project Management",
    platform: 'Google: Coursera',
    link: 'https://coursera.org/share/1e776036754a6d89e750839732f3e7b4'
  },
  {
    id: 2,
    title:"Technical Support Fundamentals",
    platform: "Google: Coursera",
    link: "https://coursera.org/share/7bdd3cd3a639ed297f82337b28d56a98"
  },
  {
    id: 3,
    title:"Programming for Everybody (Getting Started with Python)",
    platform: "Coursera: University of Michigan",
    link: "https://coursera.org/share/249996da1ac5473c2df9ff444e0e9ce2"
  },
  {
    id: 4,
    title: "Responsive Web Design",
    platform: "FreeCodeCamp",
    link: "https://www.freecodecamp.org/certification/example4"
  },
  {
    id: 5,
    title: "Create a Project Management Tracker using Microsoft Excel",
    platform: "Coursera",
    link: "https://coursera.org/share/933972d5f2b1a7f48d7afee877c23d9e"
  },
  {
    id: 6,
    title:"Getting Started with Microsoft Word",
    platform: "Coursera",
    link: "https://coursera.org/share/3857ee7295948d5770206444e5475a0f"
  },
  {
    id: 7,
    title: "Copywriting with ChatGPT: Crafting High-Quality Blog Content",
    platform: "Coursera",
    link: "https://coursera.org/share/75db19518bfdcd0514715728b03be947"
  },
  {
    id: 8,
    title: "Copywriting with ChatGPT: Produce Compelling Copy That Sells",
    platform: "Coursera",
    link: "https://coursera.org/share/41ee3a039fadc183566f565ca27da8a3"
  },
  // {
  //   id: 9,
  //   title:"Systems Thinking Basics",
  //   platform: "Coursera",
  //   link: ""
  // },
  //  {
  //   id: ,
  //   title:"Responsive Web Design Certification",
  //   platform: "FreeCodeCamp",
  //   link: ""
  // },
  //  {
  //   id: ,
  //   title:"Legacy JavaScript Algorithms and Data Structures",
  //   platform: "FreeCodeCamp",
  //   link: ""
  // },
  //  {
  //   id: ,
  //   title:"Foundational C# with Microsoft Certification",
  //   platform: "FreeCodeCamp",
  //   link: ""
  // },
];

export default certificationsData;

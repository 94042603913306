import React from 'react';

function TechnologiesTools() {
  return (
        <section id="tech-tools my-5 pt-5 mx-auto">
            <div class="skill-icons container mt-5">
                <h1 class="text-center text-2xl font-outfitBold mt-3">Technologies && Tools</h1>
                <div class="icon-box img img-fluid">
                    {/* <!--SKILLS ROW 1-->  */}
                    <div class="bg-white row pl-5 mx-auto justify-content-between">
                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=html" alt="HTML" />

                        <img class="img img-fluid" src=" https://skillicons.dev/icons?i=css" alt="CSS" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=js" alt="JavaScript" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=bootstrap" alt="Bootstrap" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=sass" alt="Sass" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=react" alt="React" />

                    </div>
                    {/* <!--SKILLS ROW 2--> */}
                    <div class="bg-white row pr-5 mx-auto justify-content-between">
                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=c" alt="C Programming Language" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=bash" alt="Bash" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=python" alt="Python" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=docker" alt="Docker" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=github" alt="Github" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=git" alt="Git" />
                    </div>
                    {/* <!--SKILLS ROW 3--> */}
                    <div class="bg-white row pl-5 mx-auto justify-content-between">
                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=nodejs" alt="NODE JS" />

                        <img class="img img-fluid" src=" https://skillicons.dev/icons?i=threejs" alt="Three js" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=mysql" alt="MySQL" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=p5js" alt="P5js" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=tailwind" alt="Tailwind" />

                        <img class="img img-fluid" src="https://skillicons.dev/icons?i=visualstudio" alt="VS Code" />

                    </div>
                </div>
            </div>
        </section>
  );
}

export default TechnologiesTools;

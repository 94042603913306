import React from 'react';
import ProjectPortfolio from '../components/ProjectComponents/ProjectPortfolio.js';

function ProjectsPage() {
  return (
    <div id='portfolio' className=' pt-16'>
      <ProjectPortfolio/>

    </div>
  );
}

export default ProjectsPage;
